import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("division filter controller connected");
  }

  static values = {
    seasonId: String,
  };

  filter(event) {
    event.preventDefault();

    const frame = document.getElementById("team_matches");

    const url = new URL(
      `/leagues/1/seasons/${this.seasonIdValue}`,
      window.location.origin
    );

    if (event.target.value === "all") {
      url.searchParams.delete("division_id");
    } else {
      url.searchParams.set("division_id", event.target.value);
    }

    // update the url without refreshing the page
    window.history.pushState(null, null, url);

    // update the turbo frame
    frame.src = url;
  }
}
